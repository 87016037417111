<template>
  <section class="main-layout" :class="{ 'main-layout--no-bar': !isAuth }">
    <unauthorized-user v-if="!isAuth" />
    <slot />

    <tab-bar v-if="isAuth" />
  </section>
</template>

<script lang="ts" src="./MainLayout.ts"></script>
<style lang="scss" src="./main-layout.scss"></style>
