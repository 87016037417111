<template>
  <popup
    :show="isShow"
    show-bar
    class="about-paid-services"
  >
    <icon-btn
      class="about-paid-services__back"
      @click="back"
    >
      <icon
        :name="IconName.ArrowBack"
        :type="IconType.Contour"
      />
    </icon-btn>
    <slot :theme-color="themeColor" />
    <div class="flex-1" />
    <btn
      w="100%"
      @click="submit"
    >
      {{ buttonTitle }}
    </btn>
  </popup>
</template>

<script lang="ts" src="./AboutPaidServices.ts" />
<style lang="scss" src="./aboutPaidServices.scss" />
