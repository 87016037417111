import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { StoreInjectionKey } from 'store/constants';
import { ChatsGetter } from 'store/chats/enums';
import { CHATS_STORE_KEY } from 'store/chats/constants';
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { APP_STORE_KEY, AppGetter } from 'store/app';
import { ITab } from './interfaces/ITab';

const TabBar = defineComponent({
  setup() {
    const route = useRoute();
    const store = useStore<typeof StoreInjectionKey>();

    const tabBarOnTop = computed(() => store.getters[`${APP_STORE_KEY}/${AppGetter.TabBarOnTop}`]);

    const fetchUrl = ref('');
    watch(() => route.path, () => {
      fetchUrl.value = '';
    });

    function isTabActive(url: string) {
      if (url === '/catalog' && route.name === 'favorites') return true;
      return route.path === url;
    }

    const count = computed(() => store.getters[`${CHATS_STORE_KEY}/${ChatsGetter.Unread}`]);
    const isProfileRaised = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsProfileRaised}`]);

    const tabs = computed<ITab[]>(() => ([
      {
        key: 'home',
        iconType: IconType.Fill,
        iconName: IconName.Home,
        url: '/',
      },
      {
        key: 'catalog',
        iconType: IconType.Fill,
        iconName: IconName.Apps,
        url: '/catalog',
      },
      {
        key: 'rooms',
        iconType: IconType.Fill,
        iconName: IconName.Message,
        url: '/rooms',
      },
      {
        key: 'profile',
        iconType: IconType.Fill,
        iconName: IconName.Profile,
        url: '/profile',
      },
    ]));

    return {
      tabs,
      count,
      isProfileRaised,
      tabBarOnTop,

      fetchUrl,
      isTabActive,

      IconName,
      IconType,
      MainColor,
      Size,
    };
  },
});

export default TabBar;
