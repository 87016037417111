import { defineComponent } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { BtnType } from 'components/Btn';
import { RouteNames } from 'router/names';

const UnauthorizedUser = defineComponent({
  setup() {
    return {
      IconName,
      IconType,
      BtnType,
      RouteNames,
    };
  },
});

export default UnauthorizedUser;
