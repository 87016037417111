<template>
  <div class="unauthorized-user">
    <btn
      class="unauthorized-user__sign-up"
      :to="{ name: RouteNames.SignUp }"
    >
      <icon
        :name="IconName.AddUser"
        :type="IconType.Contour"
        class="unauthorized-user__icon"
      />
      {{ $t('layouts.mainLayout.signUp') }}
    </btn>
    <btn
      class="unauthorized-user__sign-in"
      :type="BtnType.Text"
      outlined
      :to="{ name: RouteNames.SignIn }"
    >
      <icon
        :name="IconName.User"
        :type="IconType.Contour"
        class="unauthorized-user__icon"
      />
      {{ $t('layouts.mainLayout.signIn') }}
    </btn>
  </div>
</template>

<script lang="ts" src="./UnauthorizedUser.ts" />
<style lang="scss" src="./unauthorizedUser.scss" />
