import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { ThemeColor } from 'api/users/models/user';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { PropType } from '@vue/runtime-core';
import { StorageKeys, storageService } from 'storage';
import { IconName, IconType } from 'components/Icon';

const AboutPaidServices = defineComponent({
  props: {
    buttonTitle: {
      type: String,
      default: '',
    },
    localStorageKey: {
      type: String as PropType<StorageKeys>,
      required: false,
      default: undefined,
    },
  },
  emits: ['submit', 'closed'],
  setup(props, { emit }) {
    const store = useStore();
    const themeColor = computed<ThemeColor>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.ThemeColor}`]);

    const isShow = ref(false);
    if (props.localStorageKey) {
      const storageItem = storageService.getItem(props.localStorageKey);
      if (!storageItem && storageItem !== '1') {
        storageService.setItem(props.localStorageKey, '1');
        isShow.value = true;
      }
    } else {
      isShow.value = true;
    }

    function submit() {
      isShow.value = false;
      emit('submit');
    }

    function back() {
      isShow.value = false;
      emit('closed');
    }

    return {
      themeColor,
      isShow,
      submit,
      back,

      IconName,
      IconType,
    };
  },
});

export default AboutPaidServices;
