import { computed, defineComponent, watch } from 'vue';
import AboutPaidServices from 'components/AboutPaidServices';
import { TxtType } from 'components/Txt';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { useRoute } from 'vue-router';
import RedImg from './assets/red.raw.svg';
import BlueImg from './assets/blue.raw.svg';
import LavenderImg from './assets/lavender.raw.svg';

const TelegramUrl = process.env.VUE_APP_TELEGRAM_BOT_LINK;

const AboutTelegram = defineComponent({
  components: {
    AboutPaidServices,
  },
  emits: ['close'],
  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();
    watch(() => route.path, () => emit('close'));

    const tgToken = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.TgToken}`]);

    function handleSubmit() {
      window.open(TelegramUrl, '_blank')?.focus();
      emit('close');
    }

    function getSvgHtml(themeColor: string) {
      if (themeColor === 'blue') return BlueImg;
      if (themeColor === 'red') return RedImg;
      return LavenderImg;
    }

    return {
      handleSubmit,
      tgToken,
      getSvgHtml,
      TxtType,
    };
  },
});

export default AboutTelegram;
