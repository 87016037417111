import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import TabBar from './components/TabBar';
import UnauthorizedUser from './components/UnauthorizedUser';

const MainLayout = defineComponent({
  components: {
    TabBar,
    UnauthorizedUser,
  },
  setup() {
    const store = useStore();

    const isAuth = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]);

    return {
      isAuth,
    };
  },
});

export default MainLayout;
