<template>
  <div
    class="tab-bar"
    :class="{ 'tab-bar--on-top': tabBarOnTop }"
  >
    <div class="tab-bar__grid">
      <icon-btn
        v-for="tab in tabs"
        :key="tab.key"
        :to="tab.url"
        :size="Size.S"
        :class="{
          'tab-bar__button': true,
          'tab-bar__button--active': isTabActive(tab.url),
          'tab-bar__button--disabled': fetchUrl === tab.url,
        }"
        color="none"
        @click="fetchUrl = tab.url"
      >
        <icon
          :type="tab.iconType"
          :name="tab.iconName"
        />

        <template #marker>
          <tag
            v-if="tab.key === 'rooms' && count > 0"
            :short="true"
            :active="true"
            :size="Size.S"
            class="tab-bar__button__rooms-tag"
          >
            {{ count }}
          </tag>
          <span
            v-else-if="tab.key === 'profile' && isProfileRaised"
            class="tab-bar__premium-icon-wrapper"
          >
            <icon
              class="tab-bar__premium-icon"
              :name="IconName.Premium"
              :type="IconType.Fill"
              :size="Size.Xxs"
              :color="MainColor.White"
            />
          </span>
        </template>
      </icon-btn>
    </div>
  </div>
</template>

<script lang="ts" src="./TabBar.ts"></script>
<style lang="scss" src="./tab-bar.scss"></style>
