<template>
  <about-paid-services
    class="about-telegram"
    :button-title="$t('components.aboutTelegram.submit')"
    @submit="handleSubmit"
    @closed="$emit('close')"
  >
    <template #default="{ themeColor }">
      <txt :type="TxtType.Heading2">
        {{ $t('components.aboutTelegram.title') }}
      </txt>

      <div
        class="about-telegram__img"
        v-html="getSvgHtml(themeColor)"
      />

      <ul>
        <li>{{ $t('components.aboutTelegram.li1') }}</li>
        <li>
          <copy-text>{{ tgToken }}</copy-text>
        </li>
        <li class="mt-5">{{ $t('components.aboutTelegram.li2') }}</li>
      </ul>
      <p class="mt-5">{{ $t('components.aboutTelegram.description') }}</p>
    </template>
  </about-paid-services>
</template>

<script lang="ts" src="./AboutTelegram.ts" />
<style lang="scss" src="./aboutTelegram.scss" />
